@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .prose {
    color: hsl(var(--foreground));
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.01em;
    max-width: none;
  }

  /* 文字間距離の調整のみを行い、マージンの調整は避けました */
  .prose :where(h1, h2, h3, h4, h5, h6) {
    color: hsl(var(--foreground));
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: 0.01em; /* 文字間距離を調整 */
    scroll-margin-top: 100px;
  }

  .prose :where(p, ul, ol) {
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .prose :where(a) {
    color: hsl(var(--primary));
    text-decoration: underline;
    font-weight: 500;
    transition: color 0.2s ease;
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .prose :where(a:hover) {
    color: hsl(var(--secondary));
  }

  .prose :where(strong) {
    color: hsl(var(--foreground));
    font-weight: 600;
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .prose :where(blockquote) {
    font-style: italic;
    color: hsl(var(--muted-foreground));
    border-left-width: 0.25rem;
    border-left-color: hsl(var(--primary));
    quotes: "\201C" "\201D" "\2018" "\2019";
    padding-left: 1em;
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .prose :where(code) {
    color: hsl(var(--foreground));
    font-weight: 600;
    font-size: 0.875em;
    background-color: hsl(var(--muted));
    padding: 0.2em 0.4em;
    border-radius: 0.375rem;
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .prose :where(pre) {
    color: hsl(var(--foreground));
    background-color: hsl(var(--card));
    overflow-x: auto;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.7142857;
    border-radius: 0.375rem;
    padding: 1em 1.5em;
  }

  .prose :where(pre code) {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  .prose :where(table) {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875em;
    line-height: 1.7142857;
    /* マージンを削除 */
  }

  .prose :where(thead) {
    background-color: hsl(var(--muted));
    color: hsl(var(--foreground));
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .prose :where(tbody tr:nth-child(even)) {
    background-color: hsl(var(--muted));
  }

  .prose :where(tbody tr:nth-child(odd)) {
    background-color: hsl(var(--background));
  }

  .prose :where(th, td) {
    border: 1px solid hsl(var(--border));
    padding: 0.75em 1em;
    text-align: left;
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .prose :where(th) {
    font-weight: bold;
  }

  .prose :where(hr) {
    border-color: hsl(var(--border));
  }

  .prose :where(figure img) {
    margin: 0 auto;
    border-radius: 0.375rem;
  }

  .prose :where(figcaption) {
    color: hsl(var(--muted-foreground));
    font-size: 0.875em;
    text-align: center;
    margin-top: 0.5em;
    letter-spacing: 0.01em; /* 文字間距離を調整 */
  }

  .sticky-toc {
    position: sticky;
    top: 5rem;
    max-height: calc(100vh - 5rem);
    overflow-y: auto;
  }

  @media (max-width: 1024px) {
    .container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

/* ダークモードの調整 */
.dark .prose {
  color: hsl(var(--foreground));
}

.dark .prose :where(thead) {
  background-color: hsl(var(--muted)); /* 背景色を調整 */
}

.dark .prose :where(tbody tr:nth-child(even)) {
  background-color: hsl(var(--muted));
}

.dark .prose :where(tbody tr:nth-child(odd)) {
  background-color: hsl(var(--background));
}

.dark .prose :where(th, td) {
  border-color: hsl(var(--border));
  color: hsl(var(--foreground)); /* テキスト色を調整 */
}

.dark .prose :where(hr) {
  border-color: hsl(var(--border));
}

@media (max-width: 640px) {
  .prose {
    font-size: 0.875rem;
  }

  .prose :where(h1) {
    font-size: 1.75em;
  }

  .prose :where(h2) {
    font-size: 1.5em;
  }

  .prose :where(h3) {
    font-size: 1.25em;
  }

  .prose :where(h4) {
    font-size: 1.125em;
  }
}

/* HeroCards background shadow */
.shadow-hero {
  position: absolute;
  background: rgba(91, 134, 195, 0);
  border-radius: 24px;
  rotate: 35deg;

  width: 260px;
  height: 350px;
  filter: blur(150px);
  animation: shadow-slide infinite 4s linear alternate;

  /* 影をさらに右寄りに */
  left: 65%;
  transform: translateX(-65%);

  /* 影を大きく下に配置 */
  top: 350px;
}

@keyframes shadow-slide {
  from {
    background: rgba(91, 134, 195, 0.2);
    left: 65%; /* 初期位置をさらに右に */
    transform: translateX(-65%);
  }
  to {
    background: rgba(91, 134, 195, 0.8);
    left: 70%; /* 影を少し右に動かす */
    transform: translateX(-70%);
  }
}

@media (max-width: 1024px) {
  .shadow-hero {
    top: 150px; /* モバイル向けに下に */
    left: 65%;
    transform: translateX(-65%);
  }

  @keyframes shadow-slide {
    from {
      background: rgba(91, 134, 195, 0.2);
      left: 65%;
      transform: translateX(-65%);
    }
    to {
      background: rgba(91, 134, 195, 0.5);
      left: 70%;
      transform: translateX(-70%);
    }
  }
}

@media (max-width: 768px) {
  .shadow-hero {
    top: 150px;
    width: 100px;
    height: 350px;
    filter: blur(60px);
    left: 65%;
    transform: translateX(-65%);
  }

  @keyframes shadow-slide {
    from {
      background: rgba(91, 134, 195, 0.2);
      left: 65%;
      transform: translateX(-65%);
    }
    to {
      background: rgba(91, 134, 195, 0.3);
      left: 70%;
      transform: translateX(-70%);
    }
  }
}
